<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs12 sm4 md4 lg4 class="py-1 px-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex
        xs12
        sm4
        md4
        lg4
        v-if="!selected && ownersBusinesses.length"
        class="py-1 px-1"
      >
        <v-select
          dense
          solo
          class="caption"
          :label="$t('search business')"
          item-text="businessName"
          item-value="id"
          :items="ownersBusinesses"
          v-model="selectedShop"
          @change="onSelectShop"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm4 md4 lg4 class="text-right py-1 px-1">
        <v-text-field
          v-model="search"
          dense
          solo
          :label="$t('search employees')"
        ></v-text-field>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>

    <v-layout row wrap justify-center>
      <v-flex xs12 sm12 md12 lg12>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="leaveRequests"
          :search="search"
          :items-per-page="20"
        >
          <template v-slot:[`item.avatar`]="{ item }">
            <v-avatar size="30">
              <v-img :src="item.avatar" style="cursor: pointer">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      width="5"
                      indeterminate
                      :color="$store.state.primaryColor"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{
              item.name.first + " " + item.name.middle + " " + item.name.last
            }}
          </template>

          <template v-slot:[`item.requestStatus`]="{ item }">
            <v-btn
              v-if="item.requestStatus == 'approved'"
              outlined
              color="success"
              x-small
              left
              >{{ $t("approved") }}</v-btn
            >
            <v-btn
              v-if="item.requestStatus == 'pending'"
              outlined
              :color="$store.state.secondaryColor"
              x-small
              left
              >{{ $t("pending") }}</v-btn
            >
            <v-btn
              v-if="item.requestStatus == 'denied'"
              outlined
              color="red"
              x-small
              left
              >{{ $t("denied") }}</v-btn
            >
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              class="ma-2"
              outlined
              x-small
              :color="$store.state.secondaryColor"
              @click="leaveDetails(item.id)"
            >
              {{ $t("leave details") }}
            </v-btn>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
export default {
  data: () => ({
    loading: true,
    search: "",
    businesses: [],
    leaveRequests: [],
    employees: [],
    selectedShop: "",
    snackbarSuccess: false,
    snackbarError: false,
  }),

  computed: {
    selected() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinessesId() {
      return this.$store.getters.getBusinessId;
    },

    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
    headers() {
      return [
        {
          text: this.$t("avatar"),
          value: "avatar",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("employee ID"),
          value: "employeeId",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("name"),
          value: "name",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("start date"),
          value: "startDate",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("end date"),
          value: "endDate",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("status"),
          value: "requestStatus",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },

        {
          text: this.$t("actions"),
          value: "action",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
          filterable: false,
        },
      ];
    },
  },

  created() {
    this.getEmployees();
    this.getLeaveRequests();
  },

  methods: {
    fullName(obj) {
      for (let x in obj) {
        return obj[x] + " ";
      }
    },

    viewIndividual(empID) {
      this.$router.push({ name: "ViewIndividual", params: { id: empID } });
    },

    editEmployee(empID) {
      this.$router.push({ name: "EditEmployee", params: { id: empID } });
    },

    getEmployees() {
      this.employees = [];
      if (this.selected) {
        db.collection("employees")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.employees.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            this.loading = false;
          });
      } else {
        db.collection("employees")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.employees.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.loading = false;
          });
      }
    },

    getLeaveRequests() {
      this.leaveRequests = [];
      db.collection("leaves")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.leaveRequests.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          this.loading = false;
        });
      console.log("leave requests");
      console.log(this.leaveRequests);
    },

    onSelectShop() {
      this.loading = true;
      this.leaveRequests = [];
      db.collection("leaves")
        .where("businessId", "==", this.selectedShop)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.leaveRequests.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          this.loading = false;
        });
    },

    leaveDetails(id) {
      this.$router.push({
        name: "ViewLeaveDetails",
        params: { id: id },
      });
    },

    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
